import { Pipe, PipeTransform } from '@angular/core';
import {dayjsLocalesMap, ELang} from '@atlas-workspace/shared/models';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import { Observable, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(localeData);
dayjs.extend(utc);

@Pipe({
  name: 'messageIntervalDate',
  standalone: true
})
export class ThreadMessageDatePipe implements PipeTransform {
  public readonly unixToUtcHook = 1000;

  constructor(private trs: TranslateService) {}

  transform(value: number, interval: number = 6e4): Observable<string> {
    if (!value) return of('');

    const transformedDate = dayjs(value * this.unixToUtcHook);
    let currentLocale = dayjs.locale();
    currentLocale === 'nb' && (currentLocale = 'no');
    if (currentLocale !== this.trs.currentLang) {
      dayjs.locale(dayjsLocalesMap.get(<ELang>this.trs.currentLang));
    }

    return timer(0, interval).pipe(
      map(() => {
        const now = dayjs();

        const minutes = now.diff(transformedDate, 'minute');
        const hours = now.diff(transformedDate, 'hour');
        const days = now.diff(transformedDate, 'day');

        const textNow = this.trs.instant('Shared.Entity.Now');
        const textMinute = this.trs.instant('Shared.Entity.Minute_ago');
        const textMinutes = this.trs.instant('Shared.Entity.Minutes_ago');
        const textHour = this.trs.instant('Shared.Entity.Hour_ago');
        const textHours = this.trs.instant('Shared.Entity.Hours_ago');

        const weekDayDateFormat = transformedDate.format('dddd, HH:mm');
        const fullDateFormat = transformedDate.format('DD.MMM.YYYY, HH:mm');

        const _isToday = transformedDate.isToday();

        let date = '';
        switch (true) {
          case minutes < 1:
            date = textNow;
            break;
          case minutes <= 59 && _isToday:
            date = `${minutes} ${minutes === 1 ? textMinute : textMinutes}`;
            break;
          case minutes <= 59 && !_isToday:
          case hours >= 1 && hours <= 23 && !_isToday:
          case hours > 23 && days <= 8:
            date = weekDayDateFormat;
            break;
          case hours >= 1 && hours <= 23 && _isToday:
            date = `${hours} ${hours === 1 ? textHour : textHours}`;
            break;
          default:
            date = fullDateFormat;
        }

        return date;
      })
    );
  }
}
