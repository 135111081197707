<div class="floor-plan-header__floor-plan-menu-wr h-scrollbar v-scrollbar">
  <ng-container *ngFor="let floor of floors; index as i">
    <div class="floor-plan-header__floor-plan-menu-item-wr" atlClickOutside (clickOutside)="closeMenu(i)">
      <div
        class="floor-plan-header__floor-plan-menu-item"
        [class.active]="selectedIndex === floor.index"
        (click)="onSelectFloor(floor)"
      >
        <div *ngIf="!floor.isEditMode; else editMode">
          <div
            class="ellipsis floor-plan-header__floor-plan-name"
            container="body"
            #floorName
            [ngbTooltip]="floor?.name"
            triggers="hover touchstart"
            [disableTooltip]="floorName.scrollWidth <= floorName.clientWidth"
          >
            {{ floor?.name }}
          </div>

          <atl-custom-icon
            *ngIf="showFloorMenu && floor.created"
            class="floor-plan-header__floor-plan-menu-additional-icon"
            [class.active]="floor.isOpenMenu"
            [atlDropdownTriggerFor]="dropdownRef"
            [offsetBottom]="offsetBottom"
            (isOpen)="onOpenState(i, $event)"
            [icon]="additionalIcon"
            (click)="$event.preventDefault(); $event.stopImmediatePropagation()"
          ></atl-custom-icon>
        </div>

        <ng-template #editMode>
          <input
            class="floor-input"
            type="text"
            atlAutoFocus
            atlReplaceInput
            atlPreventDoubleSpace
            atlCapitalLetter
            [class.error]="!floor.name"
            (keydown.enter)="stopEditMode(i)"
            (blur)="stopEditMode(i)"
            [ngModel]="floor.name"
            (ngModelChange)="onSetValue($event, i)"
            [maxLength]="maxNameLength"
          />
        </ng-template>
      </div>

      <atl-dropdown #dropdownRef>
        <div class="floor-plan-header__menu-list">
          <div class="floor-plan-header__menu-item" *ngIf="canEditFloorName" (click)="onEditMode(i, $event)">
            <atl-custom-icon [icon]="pencilIcon"></atl-custom-icon>
            <span> {{ 'Shared.Entity.Rename' | translate }} </span>
          </div>
          <div
            class="floor-plan-header__menu-item delete"
            *ngIf="canRemoveFloor"
            (click)="onRemoveFloor(floor.index, $event)"
          >
            <atl-custom-icon class="remove-icon" [icon]="trashIcon"></atl-custom-icon>
            <span>{{ 'Shared.Button.Delete' | translate }}</span>
          </div>
          <div></div>
        </div>
      </atl-dropdown>
    </div>
  </ng-container>

  <ng-container *ngIf="canAddNewFloor">
    <div
      data-cy="cy-add-floor-unit-create"
      *ngIf="floors?.length < maxCountFloor"
      class="floor-plan-header__floor-plan-menu-item-wr floor-plan-header__floor-plan-menu-item-wr--add-floor"
    >
      <button
        type="button"
        class="floor-plan-header__floor-plan-menu-item floor-plan-header__floor-plan-menu-item--add"
        (click)="onAddFloor()"
      >
        {{ ( isUnitTypeCreationScope ? 'Title.Add_floor_plan' : 'UnitGeneral.Floor.Add_Floor') | translate }}
      </button>
    </div>
  </ng-container>
</div>

<ng-content></ng-content>
