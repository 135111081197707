import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { delay, of, Subject, take } from 'rxjs';

import { DropDownWritingComponent } from '../drop-down-writing/drop-down-writing.component';

@Component({
  selector: 'atl-newest-multiple-select',
  templateUrl: './newest-multiple-select.component.html',
  styleUrls: ['./newest-multiple-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewestMultipleSelectComponent),
      multi: true,
    },
  ],
})
export class NewestMultipleSelectComponent
  extends DropDownWritingComponent
  implements ControlValueAccessor, OnChanges, AfterViewInit
{
  inputValue!: string | any;
  @Input() multiple = false;
  @Input() customNotFoundView = false;
  @Input() multiLabel = false;
  @Input() multiFooter = false;
  @Input() closeOnSelect = true;
  @Input() selectableGroup = false;
  @Input() selectableGroupAsModel = false;
  @Input() customGroup = false;
  @Input() loading = false;
  @Input() trackByFn!: any;
  @Input() tabIndex = 0;
  @Input() customSearch = false;
  @Input() typeahead$?: Subject<string>;
  @Input() specificItemToScrollTo: number | null = null;
  @Input() typeToSearchText?: string;
  @Input() fixFilteringFn?: any;

  @Output() private readonly blurHandler = new EventEmitter<unknown>();
  @Output() private readonly removeItem = new EventEmitter<number>();
  @Output() private readonly addItem = new EventEmitter<unknown>();
  @Output() private readonly scrollToEndHandler = new EventEmitter<unknown>();
  @Output() private readonly dropdownCloseEvent = new EventEmitter<void>();
  @Output() private readonly emitEnterEvent = new EventEmitter<void>();
  

  @ViewChild(NgSelectComponent, { static: true }) ngSelect!: NgSelectComponent;

  onBlur(): void {
    this.onTouched();
    this.blurHandler.emit();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bindLabel?.previousValue && this.specificItemToScrollTo) {
      this.scrollToSelectedItem();
    }
  }

  writeValue(value: string): void {
    if (this.asPrimitiveSelector) {
      return;
    }
    this.inputValue = value;
    this.updateSelectedValue(this.inputValue);
  }

  onEnter(): void {
    this.emitEnterEvent.emit();
    this.onClose();
    this.dropDownInput.searchTerm = '';
  }

  scrollToSelectedItem(): void {
    if (!this.specificItemToScrollTo) {
      return;
    }

    of(null)
      .pipe(take(1), delay(100))
      .subscribe(() => {
        const index = this.listOfItems.findIndex((item) => item.id === this.specificItemToScrollTo);
        if (index !== -1 && this.ngSelect && this.ngSelect.dropdownPanel) {
          const dropdownPanel = this.ngSelect.dropdownPanel.scrollElementRef.nativeElement;
          const items = dropdownPanel.querySelectorAll('.ng-option');
          if (items.length > 0) {
            const itemToScroll = items[index];
            dropdownPanel.scrollTop = itemToScroll.offsetTop;
          }
        }
      });
  }

  compareFunction = (itemA: any, itemB: any): boolean => {
    if (typeof this.customSearch !== 'undefined' && this.customSearch !== null) {
      return !this.customSearch ? itemA[this.bindLabel] === itemB[this.bindLabel] : itemA.id === itemB.id;
    } else {
      return itemA[this.bindLabel] === itemB[this.bindLabel];
    }
  };

  private emitUpdate(value: any): void {
    if (Array.isArray(value) && Array.isArray(this.inputValue)) {
      const removedItem = this.findMissingObject(this.inputValue, value);
      if (removedItem) {
        this.removeItem.emit(removedItem.id);
      } else {
        this.addItem.emit(value);
      }
    }
  }

  changeValue(value: any): void {
    if (value instanceof Event && this.customSearch) {
      return;
    }

    if (this.multiple) {
      this.emitUpdate(value);
      this.inputValue = this.fixFilteringFn ? this.fixFilteringFn(value, this.inputValue.length) : value;
      this.onChange(this.inputValue);
    } else {
      this.savedValue = '';
      this.savedOriginalValue = '';
      this.selectedItem = value;
      const specificValue = value ? value[this.bindValue] : null;
      this.onChange(specificValue);
      this.itemSelected.emit(specificValue);
      if (this.asPrimitiveSelector) {
        return;
      }
      if (specificValue) {
        this.inputValue = specificValue;
        this.changeSelectedValue.emit(this.selectedItem);
      }
    }
    this.cdr.markForCheck();
  }

  private findMissingObject<T extends { id: string | number }>(arrOne: T[], arrTwo: T[]): T | undefined {
    return arrOne.find((objOne) => !arrTwo.some((objTwo) => objTwo.id === objOne.id));
  }

  onClose(): void {
    this.savedValue = '';
    this.isOpen = false;
    this.dropdownCloseEvent.emit();
    this.onBlur();
  }

  public onScrollToEnd(): void {
    this.scrollToEndHandler.emit();
  }
}
