<!--
  ******************************************************
  @Cypress
  - interaction with header reclamation button
  ******************************************************
-->
<section
  class="h-100 d-flex flex-column thread__wrapper"
  [ngClass]="{ fixed: !isModal, 'client-threads': scope === scopeTypes.Client }"
  [class]="scope"
>
  <header
    role="banner"
    *ngIf="
      scope &&
      scope !== scopeTypes.Reclamation &&
      scope !== scopeTypes.InternalNotes &&
      scope !== scopeTypes.InternalNotesAdmins &&
      scope !== scopeTypes.ChangeRequest
    "
    class="thread__header d-flex align-items-center justify-content-between"
    [class.client]="isClient"
  >
    <div *ngIf="thread" class="thread__header-info ellipsis">
      <ng-container *ngIf="platform === platformTypes.WEB_ADMIN; else clientTitle">
        <ng-container *ngIf="scope !== scopeTypes.Unit; else unitTitle">
          <img
            class="d-none thread__header-arrow cursor-pointer mr-12"
            src="assets/arrow-left-mobile.svg"
            alt="arrow-left"
            (click)="leaveThisThread()"
          />
          <span *ngIf="thread.units.length === 1" class="unit fz-16 lh-24 fw-500">{{
            thread.units[0].identifier
          }}</span>
          <button *ngIf="thread.units.length > 1" class="units br-4 fz-14 lh-24 fw-400" (click)="openRecipientsModal()">
            {{ thread.units.length }} {{ 'Shared.Entity.Units' | translate }}
          </button>
          <span class="thread__header-dash fz-16 lh-24 fw-500 ml-4 mr-4">{{ dashDivider }}</span>
        </ng-container>
        <ng-template [ngTemplateOutlet]="clientTitle"></ng-template>
      </ng-container>
      <ng-template #clientTitle>
        <span class="fz-16 lh-24 fw-500 ellipsis" (click)="leaveThisThread(true)">
          <img
            class="d-none thread__header-arrow cursor-pointer mr-8"
            src="assets/arrow-left-mobile.svg"
            alt="arrow-left"
          />
          {{
            thread.reclamationId
              ? ('Shared.Entity.Reclamation' | translate) + dotDivider + (thread.subject | afterSlash)
              : thread.changeRequestId
                ? ('Title.Change_request' | translate) + dotDivider + thread.subject
                : thread.subject
          }}
        </span>
      </ng-template>
      <ng-template #unitTitle>
        <span class="unit d-inline-flex cursor-pointer lh-24" (click)="leaveThisThread(false)">
          <atl-custom-icon class="thread__header-back-button" [icon]="backIcon"></atl-custom-icon>
          {{ thread.units[0].identifier + dashDivider }}
        </span>
      </ng-template>
    </div>
    <div *ngIf="thread" class="thread__header-options d-flex align-items-center">
      <div
        *ngIf="
          thread?.changeRequestId &&
          (platform === platformTypes.WEB_ADMIN ||
            (platform === platformTypes.WEB_CLIENT && moduleAccessService.accesses?.pageChangeRequests))
        "
        class="d-flex align-items-center cursor-pointer thread__header-custom-btn"
        [class.client-radius]="isClient"
        (click)="getChangeRequestDetails(thread?.changeRequestId)"
      >
        <img [src]="!isClient ? '/assets/reclamation-info.svg' : '/assets/info-icon-black.svg'" alt="icon" />
        <span class="ml-8 fz-14 lh-24 button-title ellipsis" [class.fw-500]="!isClient">{{
          'Title.Change_request_detail' | translate
        }}</span>
      </div>

      <div
        *ngIf="
          thread?.reclamationId &&
          (platform === platformTypes.WEB_ADMIN ||
            (platform === platformTypes.WEB_CLIENT && moduleAccessService.accesses?.pageReclamations))
        "
        class="d-flex align-items-center cursor-pointer thread__header-custom-btn"
        [class.client-radius]="isClient"
        data-cy="thread-header-reclamation"
        (click)="
          openReclamationModal(
            platform === platformTypes.WEB_ADMIN ? reclamationModalTemplate : reclamationDetailClient
          )
        "
      >
        <img [src]="!isClient ? '/assets/reclamation-info.svg' : '/assets/info-icon-black.svg'" alt="icon" />
        <span class="ml-8 fz-14 lh-24 button-title ellipsis" [class.fw-500]="!isClient">{{
          'Shared.Reclamation.details' | translate
        }}</span>
      </div>

      <atl-header-icon
        (click)="openAttachmentsModal()"
        [icon]="!isClient ? '/assets/message-folder-grey.svg' : '/assets/folder-black.svg'"
        [ngbTooltip]="'Shared.Entity.Attachments' | translate"
        [openDelay]="tooltipOpenDelay"
        placement="bottom"
        triggers="hover touchstart"
        [class.client]="isClient"
      ></atl-header-icon>
      <div
        *ngIf="platform === platformTypes.WEB_ADMIN && !(thread?.reclamationId || thread?.changeRequestId)"
        class="thread__header-options-btn"
        (click)="openRemoveThreadModal()"
        [ngbTooltip]="'Shared.Button.Delete' | translate"
        [openDelay]="tooltipOpenDelay"
        placement="bottom"
        triggers="hover touchstart"
      >
        <atl-custom-icon [icon]="deleteSvg"></atl-custom-icon>
      </div>
      <ng-container *ngIf="!isProjectContractor">
        <button
          *ngIf="platform === platformTypes.WEB_ADMIN"
          class="thread__header-options-btn-lg lh-24"
          [class.done]="thread.state.done"
          (click)="changeThreadState({ done: !this.thread.state.done })"
        >
          <atl-custom-icon [icon]="doneSvg"></atl-custom-icon> {{ 'Shared.Threads.Done' | translate }}
        </button>
      </ng-container>

      <atl-default-dropdown
        class="d-inline-flex mobile-dropdown-wrapper"
        *ngIf="thread && platform === platformTypes.WEB_ADMIN"
      >
        <div ngbDropdown container="body" class="d-flex">
          <div
            class="cursor-pointer dropdown-trigger d-flex align-items-center justify-content-center mr-14"
            id="dropdownMenu"
            ngbDropdownToggle
          >
            <img
              class="d-none thread__header-dots cursor-pointer"
              src="assets/dots-three-outline.svg"
              alt="dots-three-outline"
            />
          </div>
          <div ngbDropdownMenu aria-labelledby="dropdownMenu" class="dropdown-menu-280" placement="left">
            <button ngbDropdownItem *ngIf="thread?.changeRequestId && platform === platformTypes.WEB_ADMIN">
              <div
                class="d-inline-flex align-items-center fz-14"
                (click)="getChangeRequestDetails(thread?.changeRequestId)"
              >
                <atl-custom-icon [size]="16" class="mr-8" [icon]="reclamationInfoSvg"></atl-custom-icon>
                {{ 'Title.Change_request_detail' | translate }}
              </div>
            </button>

            <button
              ngbDropdownItem
              *ngIf="
                thread?.reclamationId &&
                (platform === platformTypes.WEB_ADMIN ||
                  (platform === platformTypes.WEB_CLIENT && moduleAccessService.accesses?.pageReclamations))
              "
            >
              <div
                class="d-inline-flex align-items-center fz-14"
                (click)="
                  openReclamationModal(
                    platform === platformTypes.WEB_ADMIN ? reclamationModalTemplate : reclamationDetailClient
                  )
                "
              >
                <atl-custom-icon [size]="16" class="mr-8" [icon]="reclamationInfoSvg"></atl-custom-icon>
                {{ 'Shared.Reclamation.details' | translate }}
              </div>
            </button>

            <button ngbDropdownItem>
              <div class="d-inline-flex align-items-center fz-14" (click)="openAttachmentsModal()">
                <atl-custom-icon [size]="16" class="mr-8" [icon]="documentsSvg"></atl-custom-icon>
                {{ 'Shared.Messages.Attachments' | translate }}
              </div>
            </button>
            <button
              ngbDropdownItem
              *ngIf="platform === platformTypes.WEB_ADMIN"
              class="fz-14"
              [class.done]="thread.state.done"
              (click)="markAsDone()"
            >
              <img width="16" height="16" class="mr-8" src="/assets/done-icon.svg" alt="Icon" />
              <span class="fz-14"> {{ 'Shared.Messages.Mark_done' | translate }}</span>
            </button>
            <button ngbDropdownItem class="fz-14" (click)="openRemoveThreadModal()">
              <img width="16" height="16" class="mr-8" src="/assets/trash-red.svg" alt="Icon" />
              <span class="fz-14 text-red-830">{{ 'Messages.Delete_thread' | translate }}</span>
            </button>
          </div>
        </div>
      </atl-default-dropdown>
    </div>
  </header>

  <div
    class="thread__drag-container"
    atlFileDragDrop
    (filesChangeEmiter)="droppedFiles = $event"
    (hovered)="isDragDropSectionHovered = $event"
  >
    <div *ngIf="isDragDropSectionHovered" class="dragover-overlay"></div>
    <div
      class="messages__container v-scrollbar"
      #listContainer
      [class.has-autoreply]="platform === platformTypes.WEB_CLIENT && messages.length && isOnlyBuyersMessages"
    >
      <ng-container *ngFor="let message of messages; index as i; first as isFirst; last as isLast">
        <atl-thread-message
          [message]="message"
          [isSameSender]="isSameMessageSender(i)"
          [afterDelimiter]="isNecessaryToShowDelimiter(i, isLast)"
          [threadHasOnlyOneMessage]="messages.length === 1"
          (remove)="openRemoveMessageModal($event)"
          (atlInfiniteScroll)="onNextChunk(isLast)"
          [threshold]="0.5"
          [scope]="scope"
          [changeRequest]="changeRequest"
          [unit]="unit"
          [isFirst]="isFirst"
          (unread)="changeThreadState({ read: false })"
        ></atl-thread-message>
        <div *ngIf="isAccessToShowReclamationWidget(i)">
          <ng-template *ngTemplateOutlet="reclamationDetailsInfo; context: { thread: thread }"></ng-template>
        </div>
        <div *ngIf="isAccessToShowChangeRequestWidget(i)">
          <ng-template *ngTemplateOutlet="changeReqDetailsInfo; context: { thread: thread }"></ng-template>
        </div>
        <div *ngIf="isNecessaryToShowDelimiter(i, isLast)" class="messages__sticky-date fz-12 lh-16 fw-500">
          {{ message.createdAt | messagesIntervalDateDivider | async }}
        </div>
      </ng-container>
    </div>

    <footer class="thread__footer">
      <atl-thread-editor-wrapper
        [thread]="thread"
        (submitEvent)="submit($event)"
        (openChangeRequestModal)="changeRequestModal()"
        [droppFiles]="droppedFiles"
        [scope]="scope"
        [accessTag]="accessTag"
        [disabled]="disabled"
        [isClient]="platform === platformTypes.WEB_CLIENT"
        [floorPlanItems$]="floorPlanItems$"
        (updateFloorPlan)="updateFloorPlan($event)"
        (changeSendAlsoSMSEvent)="setSendSMS($event)"
      ></atl-thread-editor-wrapper>
    </footer>
  </div>
</section>

<ng-template #reclamationModalTemplate>
  <atl-reclamation-admin-detail
    [reclamationId]="thread?.reclamationId"
    [setProjectId]="thread?.projectId"
    [modalRef]="modalRef"
    [threadViewComponent]="threadViewComponent"
    [disableSwitchingReclamation]="true"
    [disableOpenReclamation]="true"
    (removeReclamationEmit)="clearReclamationId()"
  ></atl-reclamation-admin-detail>
</ng-template>

<ng-template #reclamationDetailClient>
  <atl-reclamation-client-detail
    [reclamationId]="thread?.reclamationId"
    [modalRef]="modalRef"
    [disableSwitchingReclamation]="true"
    [disableOpenReclamation]="true"
  ></atl-reclamation-client-detail>
</ng-template>

<ng-template #reclamationDetailsInfo let-thread="thread">
  <div class="reclamation-info-bl">
    <div class="d-flex justify-content-center align-items-center">
      <img src="assets/clipboard-text-blue.svg" class="mr-4 user-select-none" alt="icon" />
      <span
        class="text-blue-980 fz-12 lh-16 fw-500 mr-8 cursor-pointer"
        (click)="
          openReclamationModal(
            platform === platformTypes.WEB_ADMIN ? reclamationModalTemplate : reclamationDetailClient
          )
        "
        >{{
          ('Shared.Entity.Reclamation' | translate) +
            ' ' +
            (thread.reclamationId ? (thread.subject | afterSlash) : thread.subject)
        }}</span
      >
      <span class="fz-12 lh-16 fw-500">{{ thread.reclamation.category }}</span>
      <span class="ml-4 mr-4">{{ '·' }}</span>
      <span class="fz-12 lh-16 fw-500">{{ thread.reclamation.type }}</span>
    </div>
    <div *ngIf="thread.reclamation.description" class="fz-12 lh-16 text-align-center word-break mt-8">
      {{ thread.reclamation.description }}
    </div>
  </div>
</ng-template>

<ng-template #changeReqDetailsInfo let-thread="thread">
  <div class="reclamation-info-bl">
    <div class="d-flex justify-content-center align-items-center">
      <img src="assets/clipboard-text-blue.svg" class="mr-4 user-select-none" alt="icon" />
      <span
        class="text-blue-980 fz-12 lh-16 fw-500 mr-8 cursor-pointer"
        (click)="getChangeRequestDetails(thread?.changeRequestId)"
        >{{ ('Title.Change_request' | translate) + ' ' + thread.subject }}</span
      >
    </div>
    <div *ngIf="thread.changeRequest.description" class="fz-12 lh-16 text-align-center word-break mt-8">
      {{ thread.changeRequest.description }}
    </div>
  </div>
</ng-template>
