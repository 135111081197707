import { CommonModule } from '@angular/common';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { DateTimePipeModule } from '@atlas-workspace/shared/pipes';
import { NgbDropdown, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { TranslateModule } from '@ngx-translate/core';

import { FirmIconComponent } from '../firm-icon/firm-icon.component';
import { HeaderIconComponent } from '../header-icon/header-icon.component';
import {fromEvent, merge, Observable} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'atl-more-dropdown',
  templateUrl: './more-dropdown.component.html',
  styleUrls: ['./more-dropdown.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HeaderIconComponent,
    NgbDropdownModule,
    TranslateModule,
    FirmIconComponent,
    DateTimePipeModule,
    NgbTooltipModule,
  ],
})
export class MoreDropdownComponent implements OnInit {
  @Input() header = true;
  @Input() body = true;
  @Input() footer = true;
  @Input() date!: string;
  @Input() user!: string;
  @Input() client = false;
  @Input() customClass = '';
  @Input() placement: PlacementArray = ['bottom-end'];
  @Input() container: 'body' | null = null;
  @Input() closeToScroll = false;

  public readonly openTooltipDelay = 500;

  @ViewChild('dropdown') private dropdownRef!: NgbDropdown;

  ngOnInit(): void {
    this.subscribeToScrollEvent();
  }

  get dropdown(): NgbDropdown {
    return this.dropdownRef;
  }

  private subscribeToScrollEvent(): void {
    if (!this.closeToScroll) return;
    merge(fromEvent(window, 'wheel'), fromEvent(window, 'scroll'))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.dropdownRef.close();
      });
  }
}
