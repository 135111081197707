<div class="image__modal_v2 modal-header d-flex align-items-center justify-content-between fw-500">
  <div *ngIf="headerData?.title">
    <p class="fz-16 lh-24 fw-500">{{ headerData?.title }}</p>
    <p *ngIf="headerData.subtitle" class="image__modal_v2 modal-header__subtitle fz-12 lh-16 text-black-810">
      {{ 'Title.Uploaded_at' | translate }} {{ headerData?.subtitle | date: 'MMM d, y' }}
    </p>
  </div>
  <div class="image__modal_v2 modal-actions d-flex align-items-center justify-content-between">
    <div class="d-flex gap-4">
      <atl-header-icon
        [icon]="isClient ? '/assets/copy-icon-v2.svg' : '/assets/copy-link.svg'"
        [ngbTooltip]="'Shared.Description.Copy_image' | translate"
        [openDelay]="tooltipDelay"
        placement="bottom"
        triggers="hover touchstart"
        [class.client]="isClient"
        (click)="onCopyImage()"
      ></atl-header-icon>
      <a class="modal-actions__link" *ngIf="headerData?.downloadLink" [href]="headerData?.downloadLink" download>
        <atl-header-icon
          [icon]="isClient ? '/assets/download-icon-v2.svg' : '/assets/download-icon-new.svg'"
          [ngbTooltip]="'Shared.Entity.Download' | translate"
          [openDelay]="tooltipDelay"
          placement="bottom"
          triggers="hover touchstart"
          [class.client]="isClient"
        ></atl-header-icon>
      </a>
    </div>
    <img src="/assets/separator.svg" alt="icon" class="mr-8 ml-8" />
    <atl-header-icon
      [icon]="isClient ? '/assets/close-icon-v2.svg' : '/assets/close_newest_gray.svg'"
      (click)="modalRef.dismiss()"
      [ngbTooltip]="'Alt.Close' | translate"
      [openDelay]="tooltipDelay"
      placement="bottom"
      triggers="hover touchstart"
      [class.client]="isClient"
    ></atl-header-icon>
  </div>
</div>

<div class="image__modal_v2 modal-body overflow-hidden p-0">
  <swiper-container
    atlSwiper
    [config]="config"
    [slides]="slides"
    init="false"
    class="image__modal_v2 swiper-container user-select-none"
    (indexChanged)="onIndexChange($event)"
  >
    <swiper-slide *ngFor="let slide of slides" class="image__modal_v2 swiper-slide">
      <ng-container *ngIf="!(slide.name | isRaw); else emptyRaw">
        <div #imageWrap class="images__wrap h-100">
          <img
            [atlImageResize]="imageWrap"
            [marginHeight]="48"
            [src]="slide[getUrlFromProperty].url"
            alt="image"
            class="image__modal_v2 slide w-100 h-100"
          />
        </div>
      </ng-container>
    </swiper-slide>
  </swiper-container>
</div>

<ng-template #emptyRaw>
  <div class="image__modal_v2__empty-image">
    <img src="assets/no_images.png" alt="raw-empty" />
  </div>
</ng-template>
