<!--
  ******************************************************
  @Cypress
  - interaction with input (e.g. click, drag-&-drop event)
  ******************************************************
-->
<div
  type="button"
  class="uploader__wrapper"
  [class.show]="showDropdown"
  atlClickOutside
  (clickOutside)="closeDropdown()"
  (focus)="displayDropdown()"
  (blur)="closeDropdown()"
  (keydown.enter)="handleUpload($event)"
  tabindex="0"
>
  <div
    atlLockField
    (isLocked)="isLocked($event)"
    (click)="openDropdown($event)"
    class="uploader__input"
    data-cy="uploader-input"
    [ngClass]="{
      show: showDropdown,
      disabled: disabled && !listOfPreview.length
    }"
  >
    <ng-container *ngIf="listOfPreview.length; else chooseFile">
      <ng-container *ngIf="!showDropdown; else chooseFile">
        <ng-container *ngTemplateOutlet="fileList"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div
    #autoscroll
    *ngIf="showDropdown"
    [class.d-none]="hiddenDropdown"
    class="uploader__dropdown v-scrollbar"
    data-cy="uploader-dropdown"
  >
    <ng-container *ngIf="listOfPreview.length || errorFilePreviews.length; else dragFile">
      <ng-container *ngTemplateOutlet="dragList"></ng-container>
    </ng-container>
  </div>
  <input
    #uploadInput
    (change)="selectedFile($event)"
    [multiple]="multiple"
    [accept]="inputAcceptString"
    [disabled]="disabled"
    class="file-input"
    hidden
    id="file-input"
    type="file"
  />
</div>

<ng-template #chooseFile>
  <ng-container *ngIf="!showDropdown && !listOfPreview.length && placeholder; else choose">
    <div class="uploader__choose placeholder">
      {{ placeholder }}
    </div>
  </ng-container>
  <ng-template #choose>
    <div *ngIf="!disabled" (click)="handleUpload($event)" class="uploader__choose">
      <img draggable="false" src="/assets/file-arrow-up.svg" alt="icon" />
      <span>{{ 'Shared.Attachment.Choose_file' | translate }}</span>
    </div>
  </ng-template>
</ng-template>

<ng-template #fileList>
  <div class="uploader__file-list" data-cy="uploader-file-list">
    <ng-container *ngFor="let filePreview of listOfPreview; index as i">
      <ng-container [ngSwitch]="filePreview.name | isImage">
        <ng-container *ngSwitchCase="true">
          <ng-container
            *ngTemplateOutlet="imagePreview ? listImage : listImageFile; context: { file: filePreview, index: i }"
          ></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <ng-container *ngTemplateOutlet="listFile; context: { file: filePreview, index: i }"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #dragFile>
  <ng-container *ngIf="!disabled">
    <div
      atlFileDragDrop
      (dragenter)="handleDragEnter()"
      (dragleave)="handleDragLeave()"
      (dragover)="handleDragOver($event)"
      (drop)="handleDrop($event)"
      (click)="handleUpload($event)"
      class="uploader__drag-files"
      data-cy="uploader-drag-files"
      [class.drag]="isDraggedOver"
    >
      <img src="/assets/image-drag-file.svg" alt="icon" />
      <h4>
        <span>{{ 'Shared.Attachment.Description.Max_upload__begin' | translate }}</span>
        <span>{{ 'Shared.Attachment.Description.Max_upload__end' | translate: { maxUpload: maxUploadedFiles } }}</span>
      </h4>
      <p>{{ 'Shared.Attachment.Description.Format_size' | translate: { extension: extensionString } }}</p>
    </div>
  </ng-container>
</ng-template>

<ng-template #dragList>
  <div
    [dragula]="dragGroup"
    [dragulaModel]="listOfPreview"
    (dragulaModelChange)="updateFilePositions($event)"
    (dragenter)="handleDragEnter()"
    (dragleave)="handleDragLeave()"
    (dragover)="handleDragOver($event)"
    (drop)="handleDrop($event)"
    [class.drag]="isDraggedOver"
    class="uploader__drop-list"
    data-cy="uploader-drop-list"
  >
    <ng-container *ngFor="let filePreview of listOfPreview; index as i">
      <ng-container [ngSwitch]="filePreview.name | isImage">
        <ng-container *ngSwitchCase="true">
          <ng-container
            *ngTemplateOutlet="
              imagePreview ? dragListImage : dragListImageFile;
              context: { file: filePreview, index: i }
            "
          ></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <ng-container *ngTemplateOutlet="dragListFile; context: { file: filePreview, index: i }"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngFor="let errorFile of errorFilePreviews; index as i">
      <ng-container *ngTemplateOutlet="dragListErrorFile; context: { file: errorFile, index: i }"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #listImageFile let-file="file" let-index="index">
  <div class="uploader__file-list-file" data-cy="dropdown-filelist-item">
    <img draggable="false" [src]="file?.name | getNameExtension | attachIcon" alt="icon" />
    <span class="w-100 ellipsis">{{ hideFilename ? '' : file.name }}</span>
    <ng-container *ngIf="!disabled">
      <img
        *ngIf="(newOnly && !isLoading) || !file.isTemp"
        (click)="removeFile($event, index, file)"
        (mousedown)="stopDefault($event)"
        class="remove"
        data-cy="dropdown-filelist-item-remove"
        src="/assets/close_gray_12.svg"
        alt="remove"
      />
    </ng-container>
  </div>
</ng-template>

<ng-template #listImage let-file="file" let-index="index">
  <div class="uploader__file-list-image" [class.disabled]="disabled" data-cy="dropdown-filelist-item">
    <img [src]="file?.previewImageW260 || file.editFile?.fileName?.url || file.editFile?.filename?.url" alt="image" />
    <ng-container *ngIf="!disabled">
      <img
        *ngIf="!isLoading"
        (click)="removeFile($event, index, file)"
        (mousedown)="stopDefault($event)"
        class="remove"
        data-cy="dropdown-filelist-item-remove"
        src="/assets/close_gray_12.svg"
        alt="remove"
      />
    </ng-container>
  </div>
</ng-template>
<ng-template #listFile let-file="file" let-index="index">
  <div class="uploader__file-list-file" data-cy="dropdown-filelist-item">
    <img
      draggable="false"
      [src]="file?.editFile?.fileExtension || file?.editFile?.mimetype || file?.fileType | attachIcon"
      alt="icon"
    />
    <span class="w-100 ellipsis">{{ hideFilename ? '' : file.name }}</span>
    <ng-container *ngIf="!disabled">
      <img
        *ngIf="!isLoading"
        (click)="removeFile($event, index, file)"
        (mousedown)="stopDefault($event)"
        class="remove"
        data-cy="dropdown-filelist-item-remove"
        src="/assets/close_gray_12.svg"
        alt="remove"
      />
    </ng-container>
  </div>
</ng-template>

<ng-template #dragListImageFile let-file="file" let-index="index">
  <div
    class="uploader__drop-list-file cursor-pointer"
    data-cy="dropdown-filelist-item"
    (click)="selectFilePreview(file.editFile, index)"
  >
    <ng-container *ngIf="!disabled">
      <ng-container *ngIf="file.isTemp && isLoading">
        <ng-container *ngTemplateOutlet="loading"></ng-container>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <img src="/assets/dots_six_vertical.svg" alt="Drag" class="drag__icon" />
      </ng-container>
    </ng-container>
    <img
      [src]="file?.editFile?.fileExtension || file?.editFile?.mimetype || (file?.name | getNameExtension) | attachIcon"
      alt="icon"
    />
    <span class="w-100 ellipsis">{{ hideFilename ? '' : file?.name }}</span>
    <a
      *ngIf="file?.editFile"
      (click)="$event.stopPropagation()"
      (mousedown)="stopDefault($event)"
      [href]="file.editFile.fileName.downloadUrl"
      class="mr-8"
    >
      <img src="assets/download-icon-new.svg" alt="download" />
    </a>
    <ng-container *ngIf="!disabled">
      <img
        *ngIf="!isLoading"
        (click)="removeFile($event, index, file)"
        (mousedown)="stopDefault($event)"
        class="remove"
        data-cy="dropdown-filelist-item-remove"
        src="/assets/trash-gray.svg"
        alt="remove"
      />
    </ng-container>
  </div>
</ng-template>
<ng-template #dragListImage let-file="file" let-index="index">
  <div
    class="uploader__drop-list-image cursor-pointer"
    data-cy="dropdown-filelist-item"
    (click)="selectFilePreview(file.editFile, index)"
  >
    <ng-container *ngIf="!disabled">
      <ng-container *ngIf="file.isTemp && isLoading">
        <ng-container *ngTemplateOutlet="loading"></ng-container>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <img src="/assets/dots_six_vertical.svg" alt="Drag" class="drag__icon" />
      </ng-container>
    </ng-container>
    <img
      class="preview"
      [src]="file?.previewImageW260 || file.editFile?.fileName?.url || file.editFile?.filename?.url"
      alt="image"
    />
    <span class="w-100 ellipsis">{{ hideFilename ? '' : file?.name }}</span>
    <a
      *ngIf="file?.editFile"
      (click)="$event.stopPropagation()"
      (mousedown)="stopDefault($event)"
      [href]="file.editFile?.fileName?.downloadUrl || file.editFile?.filename?.downloadUrl"
      class="mr-8"
    >
      <img src="assets/download-icon-new.svg" alt="download" />
    </a>
    <ng-container *ngIf="!disabled">
      <img
        *ngIf="!isLoading"
        (click)="removeFile($event, index, file)"
        (mousedown)="stopDefault($event)"
        class="remove"
        data-cy="dropdown-filelist-item-remove"
        src="/assets/trash-gray.svg"
        alt="remove"
      />
    </ng-container>
  </div>
</ng-template>
<ng-template #dragListFile let-file="file" let-index="index">
  <div
    class="uploader__drop-list-file cursor-pointer"
    data-cy="dropdown-filelist-item"
    (click)="selectFilePreview(file.editFile, index)"
  >
    <ng-container *ngIf="!disabled">
      <ng-container *ngIf="file.isTemp && isLoading">
        <ng-container *ngTemplateOutlet="loading"></ng-container>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <img src="/assets/dots_six_vertical.svg" alt="Drag" class="drag__icon" />
      </ng-container>
    </ng-container>
    <img [src]="file?.editFile?.fileExtension || file?.editFile?.mimetype || file?.fileType | attachIcon" alt="icon" />
    <span class="w-100 ellipsis">{{ hideFilename ? '' : file?.name }}</span>
    <a
      *ngIf="file?.editFile"
      (click)="$event.stopPropagation()"
      (mousedown)="stopDefault($event)"
      [href]="file.editFile.fileName.downloadUrl"
      class="mr-8"
    >
      <img src="assets/download-icon-new.svg" alt="download" />
    </a>
    <ng-container *ngIf="!disabled">
      <img
        *ngIf="!isLoading"
        (click)="removeFile($event, index, file)"
        (mousedown)="stopDefault($event)"
        class="remove"
        data-cy="dropdown-filelist-item-remove"
        src="/assets/trash-gray.svg"
        alt="remove"
      />
    </ng-container>
  </div>
</ng-template>

<ng-template #dragListErrorFile let-file="file" let-index="index">
  <div class="uploader__drop-list-file cursor-pointer file-error">
    <img
      src="/assets/warning-circle.svg"
      alt="Drag"
      [ngbTooltip]="file | attachError: maxUploadedFiles : extensionString"
      container="body"
      [placement]="tooltipPlacement"
      tooltipClass="attachment-right"
      triggers="hover touchstart"
    />
    <span class="w-100 ellipsis">{{ hideFilename ? '' : file?.name }}</span>
    <img (click)="removeErrorFile($event, index)" class="remove" src="/assets/trash-attachment-red.svg" alt="remove" />
  </div>
</ng-template>

<ng-template #loading>
  <img class="spinner" src="/assets/spinner-chart-green.svg" alt="load" />
</ng-template>
