import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  AllThreadAttachmentModel,
  BatchCreateThreadsBodyModel,
  CreateDraftRequestBodyModel,
  ETablePagination,
  EThreadFolders,
  EThreadInboxSubFolders,
  EThreadQuickFilterKey,
  EThreadQuickFilterValue,
  EUnitStatus,
  IAttachedFile,
  ICreateThreadForm,
  IMessageModelAttachmentsReq,
  IProjectUnit,
  isImageByExtension,
  ITablePagination,
  IThreadInboxSubFoldersFilter,
  IThreadState,
  IUnitItem,
  IUnitUser,
  MentionUsersModel,
  ThreadDraftInfoModel,
  ThreadDraftModel,
  ThreadFoldersCounterType,
  ThreadInboxCounterModel,
  ThreadMentionUserModel,
  ThreadMessageModel,
  ThreadModel,
  ThreadQuickFilterCounterModel,
  ThreadsFilterItemModel,
  ThreadsWithCounterModel,
  ThreadUnitsWithGroupsModel,
  ThreadUserModel,
  ThreadViewModel,
  UnitUserModel,
  UpdateMultiThreadUnitListResponseModel,
} from '@atlas-workspace/shared/models';
import { classToPlain, plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { FileHelper } from '../../helpers/file';
import { PaginationUtil } from '../../helpers/pagination.util';
import { DataTableHelperService } from '../data-table-helper/data-table-helper.service';
import { RandomColorService } from '../random-color/random-color.service';
import { ThreadsHelperService } from './threads-helper.service';

@Injectable({
  providedIn: 'root',
})
export class RestThreadsService {
  public readonly unixToUtcHook = 1000;
  private threadsPagination$ = new BehaviorSubject<ITablePagination | null>(null);
  private threadsAttachmentsPagination$ = new BehaviorSubject<ITablePagination | null>(null);
  private form = this.fb.group({ search: new FormControl('') });

  constructor(
    private http: HttpClient,
    private tableService: DataTableHelperService,
    private randomColorService: RandomColorService,
    private threadsHelperService: ThreadsHelperService,
    private dataTableHelper: DataTableHelperService,
    private fb: FormBuilder,
    @Inject('ENVIRONMENT') private env: IEnvironment
  ) {}

  get pagination$(): Observable<ITablePagination | null> {
    return this.threadsPagination$.asObservable();
  }

  get attachmentsPagination$(): Observable<ITablePagination | null> {
    return this.threadsAttachmentsPagination$.asObservable();
  }

  get threadForm(): FormGroup {
    return this.form;
  }

  public getThreads(
    projectId: number | null,
    listType: EThreadFolders,
    search = '',
    sort = '',
    paginate?: ITablePagination,
    filters?: { [key: string]: ThreadsFilterItemModel[] | IThreadInboxSubFoldersFilter | { [key: string]: boolean } },
    skipEmittingPagination = false,
    isUnitScope = false
  ): Observable<ThreadsWithCounterModel> {
    //TODO setting filters params should be refactored
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    if (projectId) params = params.set('project_id', String(projectId));
    if (listType === EThreadFolders.Done) {
      params = params.append('state[done]', 'true');
    } else if (listType === EThreadFolders.Sent) {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      params = params.append('state[answered]', 'true');
    } else if (listType === EThreadFolders.Inbox) {
      params = params.append(`state[done]`, 'false');
    }
    if (filters?.type?.length) {
      params = this.processFilterParams(params, filters, isUnitScope);
    }
    if (filters?.state?.length) {
      (filters.state as ThreadsFilterItemModel[]).forEach((item) => {
        params = params.append(`state[${item.request_field}]`, item.value);
      });
    }
    if (filters?.units?.length) {
      (filters.units as ThreadsFilterItemModel[]).forEach((item) => {
        params = params.append('unit_ids[]', item.id.toString());
      });
    }

    if (filters?.subFolders) {
      if ((filters.subFolders as IThreadInboxSubFoldersFilter)[EThreadInboxSubFolders.Answered]) {
        params = params.append('state[answered]', 'true');
      }
      if ((filters.subFolders as IThreadInboxSubFoldersFilter)[EThreadInboxSubFolders.Unanswered]) {
        params = params.append('state[answered]', 'false');
      }
      if ((filters.subFolders as IThreadInboxSubFoldersFilter)[EThreadInboxSubFolders.Unread]) {
        params = params.append('state[read]', 'false');
      }
    }
    if (filters?.reclamationView) {
      const reclamationView = filters.reclamationView as { [key: string]: boolean };
      params = params.append('my_reclamations', reclamationView['my_reclamations'].toString());
    }
    return this.http
      .get<any>(`${this.env.apiBaseUrl}api/v1/messages/message_threads`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((res) => {
          if (skipEmittingPagination) {
            return;
          }
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(res.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(res.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalPages),
          };
          this.threadsPagination$.next(pagination);
        }),
        map((res: any) => res.body?.data),
        map((data: ThreadsWithCounterModel) => plainToClass(ThreadsWithCounterModel, data))
      );
  }

  private processFilterParams(
    params: HttpParams,
    filters?: { [key: string]: ThreadsFilterItemModel[] | IThreadInboxSubFoldersFilter | { [key: string]: boolean } },
    isUnitScope = false
  ): HttpParams {
    (filters!.type as ThreadsFilterItemModel[]).forEach((item) => {
      switch (item.key) {
        case EThreadQuickFilterKey.MultiMessage:
          break;
        case EThreadQuickFilterKey.Default:
          // eslint-disable-next-line sonarjs/no-duplicate-string
          params = params.append('thread_types[]', item.value);
          if (!isUnitScope && params.getAll('thread_types[]')?.indexOf(EThreadQuickFilterValue.MultiMessage) === -1)
            params = params.append('thread_types[]', EThreadQuickFilterValue.MultiMessage);
          break;
        case EThreadQuickFilterKey.MyThreads:
          params = params.append('my_threads', 'true');
          break;
        case EThreadQuickFilterKey.ChangeRequest:
          if (
            params.getAll('thread_types[]')?.indexOf(EThreadQuickFilterValue.Messageable) === -1 ||
            params.getAll('thread_types[]') === null
          )
            params = params.append('thread_types[]', EThreadQuickFilterValue.Messageable);
          params = params.append('messageable_types[]', 'ChangeRequests::Request');
          break;

        case EThreadQuickFilterKey.Reclamation:
          if (
            params.getAll('thread_types[]')?.indexOf(EThreadQuickFilterValue.Reclamation) === -1 ||
            params.getAll('thread_types[]') === null
          )
            params = params.append('thread_types[]', EThreadQuickFilterValue.Reclamation);
          params = params.append('messageable_types[]', 'Reclamation');
          break;

        default:
          params = params.append('thread_types[]', item.value);
          break;
      }
    });

    return params;
  }

  /**
   * Batch create message threads
   * @param body BatchCreateThreadsBodyModel
   * @returns Observable\<ThreadModel[]>
   */
  public batchCreateThreads(body: BatchCreateThreadsBodyModel): Observable<ThreadModel[]> {
    return this.http.post(`${this.env.apiBaseUrl}api/v1/messages/message_threads/batch_create`, body).pipe(
      map((res: any) => res.data.message_threads),
      map((data) => plainToClass(ThreadModel, <ThreadModel[]>data))
    );
  }

  /**
   * Batch update thread states
   * @param thread_ids
   * @param state
   * @returns Observable\<null>
   */
  public batchUpdateThreadsStates(thread_ids: number[], state: Partial<IThreadState>): Observable<null> {
    return this.http.put<null>(`${this.env.apiBaseUrl}api/v1/messages/message_threads/states`, { thread_ids, state });
  }

  /**
   * Batch destroy threads
   * @param thread_ids number[]
   * @returns Observable\<null>
   */
  public batchDestroyThreads(thread_ids: number[]): Observable<null> {
    return this.http.request<null>('delete', `${this.env.apiBaseUrl}api/v1/messages/message_threads/batch_destroy`, {
      body: { thread_ids },
    });
  }

  /**
   * Update units list of multi thread
   * @param id number
   * @param unit_ids number[]
   * @returns Observable\<UpdateMultiThreadUnitListResponseModel>
   */
  public updateMultiThreadUnitList(id: number, unit_ids: number[]): Observable<UpdateMultiThreadUnitListResponseModel> {
    return this.http.put(`${this.env.apiBaseUrl}api/v1/messages/message_threads/${id}`, { unit_ids }).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(UpdateMultiThreadUnitListResponseModel, data))
    );
  }

  /**
   * Get messages list of thread
   * @param threadId number
   * @param paginate ITablePagination
   * @returns Observable<[ThreadMessageModel[], ITablePagination]>
   * @Cypress
   */
  public getThreadMesssages(
    threadId: number,
    paginate?: ITablePagination
  ): Observable<[ThreadMessageModel[], ITablePagination]> {
    const params: HttpParams = this.tableService.paramsHandler('', '', paginate);
    return this.http
      .get<any>(`${this.env.apiBaseUrl}api/v1/messages/message_threads/${threadId}/messages`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((data) => {
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(data.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(data.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(data.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(data.headers, ETablePagination.TotalPages),
          };
          const messages = plainToClass(ThreadMessageModel, <[]>data.body?.data?.messages);
          messages.forEach((msg) => {
            msg.attachments.forEach((atch) => (atch.isImage = isImageByExtension(atch.extension!)));
          });
          return [messages, pagination];
        })
      );
  }

  /**
   * Create thread message
   * @param threadId number
   * @param text string
   * @param attachment_ids number[]
   * @param mentions ThreadMentionUserModel[]
   * @param messages_model_attachments IMessageModelAttachmentsReq[]
   * @returns Observable\<ThreadMessageModel>
   * @Cypress
   */
  public createThreadMesssage(
    threadId: number,
    text: string,
    attachment_ids: number[],
    mentions: ThreadMentionUserModel[],
    messages_model_attachments: IMessageModelAttachmentsReq[],
    send_via_sms = false
  ): Observable<ThreadMessageModel> {
    return this.http
      .post(`${this.env.apiBaseUrl}api/v1/messages/message_threads/${threadId}/messages`, {
        text,
        attachment_ids,
        mentions,
        messages_model_attachments,
        send_via_sms,
      })
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ThreadMessageModel, data))
      );
  }

  /**
   * Delete specified thread message
   * @param threadId number
   * @param messageId number
   * @returns Observable\<null>
   */
  public deleteThreadMessage(threadId: number, messageId: number): Observable<null> {
    return this.http.delete<null>(
      `${this.env.apiBaseUrl}api/v1/messages/message_threads/${threadId}/messages/${messageId}`
    );
  }

  public getDraftThreads(
    projectId: number | null,
    search = '',
    sort = '',
    paginate?: ITablePagination,
    unitId?: number,
    skipEmittingPagination = false
  ): Observable<ThreadDraftModel[]> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    if (projectId) params = params.set('project_id', String(projectId));
    if (unitId) params = params.set('unit_id', String(unitId));
    return this.http
      .get<any>(`${this.env.apiBaseUrl}api/v1/messages/drafts`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((res) => {
          if (skipEmittingPagination) {
            return;
          }
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(res.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(res.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalPages),
          };
          this.threadsPagination$.next(pagination);
        }),
        map((res) => res.body.data?.drafts),
        map((data: ThreadDraftModel[]) => plainToClass(ThreadDraftModel, data))
      );
  }

  /**
   * Create draft
   * @param instance CreateDraftRequestBodyModel
   * @returns Observable\<CreateDraftResponseModel>
   */
  public createDraft(instance: CreateDraftRequestBodyModel): Observable<ThreadDraftModel> {
    const body = classToPlain(instance, { exposeUnsetFields: false });
    return this.http.post(`${this.env.apiBaseUrl}api/v1/messages/drafts`, body).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(ThreadDraftModel, data))
    );
  }

  public getDraftInfo(draftId: number): Observable<ThreadDraftInfoModel> {
    return this.http
      .get<any>(`${this.env.apiBaseUrl}api/v1/messages/drafts/${draftId}`, {
        observe: 'response',
      })
      .pipe(
        map((res: any) => res.body?.data),
        map((data) => plainToClass(ThreadDraftInfoModel, data))
      );
  }

  /**
   * Update draft
   * @param instance CreateDraftRequestBodyModel
   * @returns Observable\<CreateDraftResponseModel>
   */
  public updateDraft(instance: CreateDraftRequestBodyModel, draftId: number): Observable<ThreadDraftModel> {
    const body = classToPlain(instance, { exposeUnsetFields: false });
    return this.http.put(`${this.env.apiBaseUrl}api/v1/messages/drafts/${draftId}`, body).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(ThreadDraftModel, data))
    );
  }

  /**
   * Delete specified draft
   * @param draftId number
   * @returns Observable\<any>
   */
  public deleteDraft(draftId: number): Observable<any> {
    return this.http.delete(`${this.env.apiBaseUrl}api/v1/messages/drafts/${draftId}`);
  }

  /**
   * Get members list for mentions
   * @param project_id number
   * @param unit_ids number[]
   * @returns Observable\<ThreadUserModel[]>
   */
  public getMembersForMentions(project_id: number, unit_ids: number[]): Observable<ThreadUserModel[]> {
    let params: HttpParams = this.tableService.paramsHandler('', '', { pageItems: 1000 });
    params = params.set('project_id', String(project_id));
    unit_ids.forEach((id) => (params = params.append('unit_ids[]', String(id))));
    return this.http
      .get<any>(`${this.env.apiBaseUrl}api/v1/messages/members/mention`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((res) => res.body.data?.members),
        map((data) => <ThreadUserModel[]>data)
      );
  }

  /**
   * Get all message attachments of thread
   * @param threadId
   * @returns
   */
  public getThreadAttachments(
    threadId: number,
    search = '',
    paginate?: ITablePagination
  ): Observable<AllThreadAttachmentModel[]> {
    const params: HttpParams = this.tableService.paramsHandler(search, '', paginate);
    return this.http
      .get<any>(`${this.env.apiBaseUrl}api/v1/messages/message_threads/${threadId}/attachments`, {
        params: params,
        observe: 'response',
      })
      .pipe(
        tap((res) => {
          const pagination = this.dataTableHelper.getPagination(res);
          this.threadsAttachmentsPagination$.next(pagination);
        }),
        map((res) => res.body.data?.attachments),
        map((data: AllThreadAttachmentModel[]) => plainToClass(AllThreadAttachmentModel, data)),
        map((data: AllThreadAttachmentModel[]) => {
          return this.convertAttachmentsObj(data);
        })
      );
  }

  private convertAttachmentsObj(attachments: AllThreadAttachmentModel[]): AllThreadAttachmentModel[] {
    return attachments.map((i) => {
      if (i.file.extension) i.file.type = FileHelper.handleFileType(i.file.extension);

      i.file.isImage = this.threadsHelperService.isImage(i.file);
      return {
        ...i,
        createdAt: i.createdAt * this.unixToUtcHook,
      };
    });
  }

  /**
   * Get object of counters for each folder (global/project)
   * @note If the ``project_id`` argument is not passed, the response will contains counters
   * for the entire firm, since ``firm_id`` will be passed by default.
   * @param project_id number
   * @returns Observable\<ThreadFoldersCounterType>
   */
  public getFoldersCounter(projectId?: number, unitId?: number): Observable<ThreadFoldersCounterType> {
    let params: HttpParams = new HttpParams();
    if (projectId) params = params.set('project_id', String(projectId));
    if (unitId) params = params.set('unit_id', String(unitId));
    return this.http.get(`${this.env.apiBaseUrl}api/v1/messages/message_threads/main_counter`, { params }).pipe(
      map((res: any) => res.data),
      map((data) => <ThreadFoldersCounterType>data)
    );
  }

  /**
   * Get map of inbox folred counters (global/project/unit)
   * @note If the `project_id` argument is not passed, the response will contains counters for the
   * entire firm, since `firm_id` will be passed by default.
   * @note If the `unit_id` argument is not passed, the response will contains counters for the entire
   * firm as well, since `firm_id` will be passed by default.
   * @param projectId number
   * @param unitId number
   * @returns Observable\<ThreadInboxCounterModel>
   */
  public getInboxCounter(projectId?: number, unitId?: number): Observable<ThreadInboxCounterModel> {
    let params: HttpParams = new HttpParams();
    if (projectId) params = params.set('project_id', String(projectId));
    if (unitId) params = params.set('unit_id', String(unitId));
    return this.http.get(`${this.env.apiBaseUrl}api/v1/messages/message_threads/inbox_counter`, { params }).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(ThreadInboxCounterModel, data))
    );
  }

  /**
   * Get all unread messages counter for specified scope (global/project/unit)
   * @note If the `project_id` argument is not passed, the response will contains unread counter for
   * the entire firm, since `firm_id` will be passed by default.
   * @note If the `unit_id` argument is not passed, the response will contains unread counter for the
   * entire firm as well, since `firm_id` will be passed by default.
   * @param projectId number
   * @param unitId number
   * @returns Observable\<number>
   */
  public getUnreadMessagesCounter(projectId?: number, unitId?: number): Observable<number> {
    let params: HttpParams = new HttpParams();
    if (projectId) params = params.set('project_id', String(projectId));
    if (unitId) params = params.set('unit_id', String(unitId));
    return this.http
      .get(`${this.env.apiBaseUrl}api/v1/messages/message_threads/unread_messages_counter`, { params })
      .pipe(map((res: any) => <number>res.data.all));
  }

  /**
   * Get units list for creation thread
   * @param projectId number
   * @param search string
   * @param paginate ITablePagination
   * @returns Observable\<[UnitUserModel[], ITablePagination]>
   */
  public getUnitsForCreation(
    projectId: number,
    search = '',
    paginate?: ITablePagination,
    sort = 'default'
  ): Observable<[UnitUserModel[], ITablePagination]> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    // eslint-disable-next-line sonarjs/no-duplicate-string
    params = params.append('sale_statuses[]', EUnitStatus.Sold).append('sale_statuses[]', EUnitStatus.Reserved);
    return this.http
      .get<any>(`${this.env.apiBaseUrl}api/v1/projects/${projectId}/units_users`, {
        params: params,
        observe: 'response',
      })
      .pipe(
        map((data) => {
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(data.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(data.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(data.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(data.headers, ETablePagination.TotalPages),
          };
          return <[UnitUserModel[], ITablePagination]>[
            plainToClass(UnitUserModel, <[]>data.body.data.units),
            pagination,
          ];
        })
      );
  }

  public getUnitsWithGroups(
    projectId: number,
    search = '',
    paginate: ITablePagination,
    sort = 'default'
  ): Observable<[ThreadUnitsWithGroupsModel[], ITablePagination]> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    if (projectId) params = params.set('project_id', String(projectId));
    return this.http
      .get<any>(`${this.env.apiBaseUrl}api/v1/projects/${projectId}/layout_types/units_groups_and_units`, {
        params,
        observe: 'response',
      })
      .pipe(
        map(({ headers, body }) => {
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(headers, ETablePagination.TotalPages),
          };
          return [plainToClass(ThreadUnitsWithGroupsModel, <unknown[]>body.data.layout_groups_or_units), pagination];
        })
      );
  }

  /**
   * Store list of message thread attachments
   * @param body FormData
   * @returns Observable\<IAttachedFile[]>
   * @Cypress
   */
  public saveThreadAttachments(files: File[]): Observable<IAttachedFile[]> {
    if (files.length) {
      const body = new FormData();
      let headers = new HttpHeaders();
      files.forEach((file, i) => {
        headers = i ? headers.append('Accept', file.type) : headers.set('Accept', file.type);
        body.append(`file_resources_attributes[][filename]`, new Blob([file]), file.name);
      });
      return this.http.post(`${this.env.apiBaseUrl}api/v1/files/batch_create`, body, { headers }).pipe(
        map((res: any) => res.data.files),
        map((data) => <IAttachedFile[]>data)
      );
    }
    return of([]);
  }

  public getFilesByIds(ids: number[]): Observable<IAttachedFile[]> {
    let params: HttpParams = new HttpParams();
    ids.forEach((id) => {
      params = params.append('ids[]', String(id));
    });
    return this.http.get(`${this.env.apiBaseUrl}api/v1/files`, { params }).pipe(
      map((res: any) => res.data.files),
      map((data) => <IAttachedFile[]>data)
    );
  }

  public getUnitsForFilter(
    projectId: string,
    sort = 'default',
    search = '',
    paginate?: ITablePagination,
    filter: string[] = []
  ): Observable<ThreadsFilterItemModel[]> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    if (filter.length) filter.forEach((v) => (params = params.append('sale_statuses[]', v)));
    return this.http
      .get<any>(`${this.env.apiBaseUrl}api/v1/projects/${projectId}/units_users`, {
        params: params,
        observe: 'response',
      })
      .pipe(
        map((res) => res.body.data.units),
        map((data: IUnitUser[]) => plainToClass(ThreadsFilterItemModel, data))
      );
  }

  // TODO: Approach should be changed when the API will have appropriate endpoint.
  public getAllFirmUnitsAndProjects(): Observable<IProjectUnit[]> {
    return this.http
      .get(`${this.env.apiBaseUrl}api/v1/projects/units`)
      .pipe(map((res: any) => <IProjectUnit[]>res.data.projects));
  }

  /**
   * @Cypress
   */
  public getAllFirmUnits(projectIds: string[], search = '', paginate?: ITablePagination): Observable<IUnitItem[]> {
    let params: HttpParams = this.tableService.paramsHandler(search, 'default', paginate);
    if (projectIds.length) projectIds.forEach((v) => (params = params.append('project_ids[]', v)));

    return this.http
      .get<any>(`${this.env.apiBaseUrl}api/v1/units/short_index`, {
        params: params,
        observe: 'response',
      })
      .pipe(map((res) => <IUnitItem[]>res.body?.data?.units));
  }

  /**
   * Method to get specified thread by `id`
   * @param id number
   * @returns Observable\<ThreadViewModel>
   */
  public getThreadById(id: number): Observable<ThreadViewModel> {
    return this.http.get(`${this.env.apiBaseUrl}api/v1/messages/message_threads/${id}`).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(ThreadViewModel, data))
    );
  }

  // Client methods
  public getThreadsClient(
    projectId: number | null,
    unitId: number,
    search = '',
    sort = '',
    paginate?: ITablePagination,
    skipEmittingPagination = false
  ): Observable<ThreadModel[]> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    if (projectId) params = params.set('project_id', String(projectId));
    params = params.set('unit_id', String(unitId));
    return this.http
      .get<any>(`${this.env.apiBaseUrl}api/v1/messages/message_threads`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((res) => {
          if (skipEmittingPagination) {
            return;
          }
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(res.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(res.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalPages),
          };
          this.threadsPagination$.next(pagination);
        }),
        map((res) => res.body.data.message_threads),
        map((data: ThreadModel[]) => plainToClass(ThreadModel, data))
      );
  }

  public batchCreateThreadsClient(
    projectId: number,
    unitId: number,
    formValues: ICreateThreadForm,
    savedFiles: IAttachedFile[],
    mentions: ThreadMentionUserModel[]
  ): Observable<ThreadModel[]> {
    const body: BatchCreateThreadsBodyModel = {
      project_id: projectId,
      subject: formValues.subject,
      text: this.threadsHelperService.removeWhitespaces(formValues.body),
      unit_ids: [unitId],
      attachment_ids: savedFiles.map((f) => f.id),
      mentions: mentions,
    };
    return this.http.post(`${this.env.apiBaseUrl}api/v1/messages/message_threads/batch_create`, body).pipe(
      map((res: any) => <ThreadModel[]>res.data.message_threads),
      map((data) => plainToClass(ThreadModel, data))
    );
  }

  public getQuickFilterCounters(
    projectId: number,
    listType: EThreadFolders | EThreadInboxSubFolders,
    done: boolean,
    unitId?: number
  ): Observable<ThreadQuickFilterCounterModel> {
    let params: HttpParams = new HttpParams();
    if (projectId) params = params.set('project_id', String(projectId));
    if (listType)
      params = params.set('folder', listType === EThreadInboxSubFolders.Unanswered ? 'not_answered' : listType);
    if (unitId) params = params.set('unit_id', String(unitId));

    params = params.set('state[done]', done.toString());
    return this.http.get(`${this.env.apiBaseUrl}api/v1/messages/message_threads/quick_filter_counter`, { params }).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(ThreadQuickFilterCounterModel, data))
    );
  }

  public updateDeliveredStateAll(unitId: number): Observable<any> {
    return this.http.put(`${this.env.apiBaseUrl}api/v1/messages/messages/mark_delivered_all_messages/${unitId}`, {});
  }

  public getUnitsMainCounter(unitId?: number): Observable<ThreadFoldersCounterType> {
    return this.http.get(`${this.env.apiBaseUrl}api/v1/messages/message_threads/${unitId}/main_counter`).pipe(
      map((res: any) => res.data),
      map((data) => <ThreadFoldersCounterType>data)
    );
  }

  public getUsersForMentions(
    threadId: number,
    paginate: ITablePagination,
    unregistered = false
  ): Observable<MentionUsersModel[]> {
    let params: HttpParams = this.tableService.paramsHandler('', '', paginate);
    params = params.set('with_unregistered', unregistered);
    return this.http
      .get<any>(`${this.env.apiBaseUrl}api/v1/messages/message_threads/${threadId}/mentionable_characters`, { params })
      .pipe(
        map((res: any) => res.data.characters),
        map((data: MentionUsersModel[]) => plainToClass(MentionUsersModel, data))
      );
  }
}
